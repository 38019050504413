
import { getGetters } from '@/store/getters'
import { getMutations } from '@/store/mutations'
import { getActions } from '@/store/actions'
import { Initial as model } from '@/models/api_v1'
import { EmmaService as service } from '@/services/api_v1/emma'
import { useStore } from 'vuex'


// Init
const getInitialState = () => ({
  isInit: null,
  items: null,
  itemsMap: {},
  itemInit: model.initialState(),
});

// Import
const getters = getGetters()
const mutations = getMutations({ model })
const actions = {
  ...getActions({ model, service, init: getInitialState() }),

  async fetchThread({ state, commit, dispatch }, barcode) {
    let output = null
    try {
      output = await service.fetchThread(barcode)
        .then((response) => {
          return response
        })
    } catch (error) {
      console.error('Emma.fetchThread', error)
      throw error
    }
    return output
  },
  async createMessage({ state, commit, dispatch }, {
    message,
    barcode
  }) {
    let output = null
    try {
      output = await service.createMessage(message, barcode)
        .then((response) => {
          return response
        })
    } catch (error) {
      console.error('Emma.createMessage', error)
      throw error
    }
    return output
  },
  async createThread({ state, commit, dispatch }, {
    test_type,
    barcode,
    patient_name,
    patient_gender
  }) {
    let output = null
    try {
      output = await service.createThread({
        test_type,
        barcode,
        patient_name,
        patient_gender
      }).then((response) => {
        return response
      })
    } catch (error) {
      console.error('Emma.createThread', error)
      throw error
    }
    return output
  },
}

// Export
export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  mutations,
  actions
}