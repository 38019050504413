const prefix = ''

const LoginPage = () => import('@/v2/pages/auth/Auth')
const RequestsPage = () => import('@/components/cms/requests/RequestsPage')
const RequestPage = () => import('@/components/cms/request/RequestPage')
const SymptomsPage = () => import('@/components/cms/symptoms/SymptomsPage')
const ConclusionPage = () => import('@/components/cms/conclusion/ConclusionPage')
const ReportPage = () => import('@/components/cms/report/ReportPage')
const TestPage = () => import('@/components/cms/test/TestPage')
const TestPageStepan = () => import('@/components/cms/test/TestPageStepan')

export const cmsRoutes = window.location.host.split('.')[0] === 'cms' || window.location.host.split('.')[0] === 'fgc-cms' ?
    [
        {
            path: `${prefix}/auth`,
            name: 'cms.auth',
            component: LoginPage,
        },
        {
            path: `${prefix}/auth/code`,
            name: 'cms.auth.code',
            component: LoginPage,
        },
        {
            path: `${prefix}/auth/new-password`,
            name: 'new-password',
            component: LoginPage,
        },
        {
            path: `${prefix}`,
            redirect: {name: 'cms.requests'},
        },
        {
            path: `${prefix}/requests`,
            name: 'cms.requests', // home
            component: RequestsPage,
        },
        {
            path: `${prefix}/requests/:barcode`,
            name: 'cms.request',
            component: RequestPage,
        },
        {
            path: `${prefix}/requests/:barcode/symptoms`,
            name: 'cms.symptoms',
            component: SymptomsPage,
        },
        {
            path: `${prefix}/requests/:barcode/conclusion`,
            name: 'cms.conclusion',
            component: ConclusionPage,
        },
        {
            path: `${prefix}/requests/:barcode/report`,
            name: 'cms.report',
            component: ReportPage,
        },
        {
          path: `${prefix}/test`,
          name: 'cms.test',
          component: TestPage,
        },
        {
          path: `${prefix}/test-stepan`,
          name: 'cms.test.stepan',
          parameters: {barcode: 'barcode'},
          component: TestPageStepan,
        }
    ] : []
