import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/img/logo_big.svg'


const _hoisted_1 = { class: "logo-wrapper" }
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  alt: "Logo",
  width: "185"
}
const _hoisted_3 = { key: 1 }


export default /*@__PURE__*/_defineComponent({
  __name: 'LogoTitle',
  props: {
  title: {
    type: String,
    default: ''
  },
  show_logo: {
    type: Boolean,
    default: true
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.show_logo)
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _createCommentVNode("", true),
    (__props.title)
      ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString(__props.title), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})