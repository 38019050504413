import { http } from "@/shared/api";

const llm_url = 'https://fgc-dev.rbbrduck.co.uk/ms/llm/api/v1/';

const fetchThreads = async () => {
  const response = await http.get(llm_url + 'threads');
  return response.data.data;
}
const fetchThread = async (barcode) => {
  const response = await http.get(llm_url + 'threads/msg?barcode=' + barcode);
  return response.data.data;
}
const createMessage = async (message, barcode) => {
  const response = await http.post(llm_url + 'threads/messages/create', {
    message,
    barcode
  });
  return response.data;
}
const createThread = async ({
  test_type,
  barcode,
  patient_name,
  patient_gender
}) => {
  const response = await http.post(llm_url + 'threads/create');
  return response.data;
}

export default {
  fetchThreads,
  fetchThread,
  createMessage,
  createThread
}
