import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

declare global {
  interface Window {
    Pusher: any; 
    Echo: Echo<any>; 
  }
}
window.Pusher = Pusher;
export const ws = new Echo({
  broadcaster: 'reverb',
  key: process.env.VUE_APP_WS_KEY,
  wsHost: process.env.VUE_APP_WS_HOST,
  wsPort: process.env.VUE_APP_WS_PORT,
  wssPort: process.env.VUE_APP_WSS_PORT,
  forceTLS: process.env.VUE_APP_WS_TLS === 'https' ? true : false,
  enabledTransports: ['ws', 'wss'],
  authEndpoint: process.env.VUE_APP_WS_AUTH,
  auth: {
      headers: {
          Authorization: `Bearer ${localStorage.getItem("patient_access_token")}`,
          Accept: "application/json",
      }
  },
});

export function initWSToken() {
  ws.connector.options.auth.headers.Authorization = `Bearer ${localStorage.getItem("patient_access_token")}`;
}